.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: var(--normal-radius);
  border: 1px solid var(--blue-accent);
  background: var(--blue-accent);
  color: #fff;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  line-height: var(--body-line-height);
  text-align: center;
  cursor: pointer;
  outline: none;
  user-select: none;
  transition: background-color var(--transition-time);

  &:focus {
    outline: 1px solid var(--blue-accent);
  }

  &:active {
    transform: scale(0.99);
  }

  &:hover {
    text-decoration: none;
    background: var(--alt-blue-accent);
    border-color: var(--alt-blue-accent);
  }

  &.ghost {
    color: var(--blue-accent);
    background-color: transparent;
    border-color: var(--lighter-text-color);

    &:hover {
      background: var(--blue-50);
      border-color: var(--blue-accent);
    }
  }

  &.action {
    color: var(--blue-accent);
    background-color: transparent;
    border-color: transparent;
    border-radius: var(--small-radius);

    &:hover {
      background-color: var(--blue-50);
    }
  }

  &.cancel,
  &.delete {
    background-color: transparent;
    border-color: var(--lighter-text-color);

    &:hover {
      color: #fff;
    }
  }

  &.cancel {
    color: var(--text-color);

    &:hover {
      background: var(--lighter-text-color);
      border-color: var(--lighter-text-color);
    }
  }

  &.delete {
    color: var(--red-accent);

    &:hover {
      background: var(--red-accent);
      border-color: var(--red-accent);
    }
  }

  &.full {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }

  &:disabled,
  &:disabled:hover {
    background: var(--border-color);
    color: var(--light-text-color);
    border-color: var(--border-color);
    cursor: not-allowed;
  }

  // Sizes
  &.small {
    padding: rem(3px) 0.5rem;
    font-size: var(--small-font-size);
  }

  &.micro {
    padding: 0 0.25rem;
    border-radius: var(--small-radius);
    font-size: var(--smaller-font-size);
  }
}
