/* ----- Positions ----- */
@include prototype-list($positions, position);

// ----- offset  ----- //
@each $direction in $directions {
  @include prototype-list($spacings, $direction, $direction);
}

/* ----- Stacking cotntext ----- */
@include variables-class($z-index, z-index);

.sticky-element {
  position: sticky;
  top: var(--top-sticky);
}

.sticky-element-to-header {
  position: sticky;
  top: var(--header-height);
}
