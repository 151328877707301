.payroll-process {
  --process-tabs-height: 3rem;

  & .tabs-processes {
    position: sticky;
    top: var(--header-height);
    height: var(--process-tabs-height);
    border-bottom: 1px solid var(--border-color);
    background-color: var(--body-bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .name-process {
    opacity: 0;
    transform: translateY(-10px);
    transition: all var(--transition-time);
  }

  & .tabs-processes.is-sticky {
    z-index: var(--z-fixed);
  }

  & .tabs-processes.is-sticky .name-process {
    opacity: 1;
    transform: translateY(0);
  }

  .employees-panel {
    position: sticky;
    top: calc(var(--header-height) + var(--process-tabs-height));
    height: calc(var(--viewport-height) - var(--process-tabs-height) + var(--container-padding));

    .panel-section:last-child {
      --employees-panel-list-height: calc(
        var(--viewport-height) - var(--employees-panel-header-height) -
          (var(--container-padding)) - var(--employees-panel-gap) - var(--process-tabs-height)
      );
    }
  }

  .novelty-table thead {
    position: sticky;
    top: calc(var(--header-height) + var(--process-tabs-height));
  }
}
