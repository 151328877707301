.table-template {
  padding: 0;
  --side-padding: 1rem;

  @include from(m) {
    --side-padding: 1.5rem;
  }

  @include from(lg) {
    --side-padding: 2rem;
  }

  table {
    border-collapse: collapse;

    .action-button .icon {
      @include size(1.5rem);
    }
  }

  thead,
  tbody tr:nth-of-type(even) {
    background-color: var(--body-alt-bg);
  }

  tbody tr:hover {
    background-color: var(--blue-50);
  }

  td,
  th {
    &:first-of-type {
      padding-left: var(--side-padding);
    }

    &:last-of-type {
      padding-right: var(--side-padding);
    }
  }

  tr {
    transition: background-color var(--transition-time);
  }

  tr.active {
    background-color: var(--light-blue-accent) !important;
  }

  th:last-of-type {
    @include from(m) {
      // position       : absolute;
      // opacity        : 0;
      // pointer-events : none;
    }
  }

  &.small td,
  &.small th,
  &.smaller td,
  &.smaller th {
    padding: 0.5rem;
  }

  .odd {
    @include to(m) {
    }

    @include from(m) {
      thead,
      tbody tr:nth-of-type(even):not(:hover) {
        background: initial;
      }

      tbody tr:nth-of-type(odd):not(:hover) {
        background-color: var(--body-alt-bg);
      }
    }
  }
}
