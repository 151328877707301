.user-avatar {
  --size: 1.5rem;
  @include size(var(--size));
  border-radius: 50%;
  overflow: hidden;
  flex: none;

  @include from(m) {
    --size: 2rem;
  }

  img {
    width: 100%;
    height: 100%;
  }

  &.small {
    --size: 1.25rem;

    @include from(m) {
      --size: 1.5rem;
    }
  }

  &.bigger {
    --size: 3rem;

    @include from(m) {
      --size: 4rem;
    }
  }

  .user-avatar-list & {
    box-shadow: 0 0 0 2px white;

    &:not(:first-of-type) {
      margin-left: -0.25rem;
    }
  }

  &.is-loading {
    position: relative;
    cursor: not-allowed;
    border: 4px solid var(--text-color);

    &::before {
      content: 'Loading...';
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 0.4rem;
      font-size: 0.6rem;
      color: var(--white-color);
      background-image: url('../../../static/images/utils/default-user.svg');
      background-size: cover;
    }
  }
}
