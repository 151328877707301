.main-header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--container-bg);
  height: var(--header-height);
  border-bottom: 1px solid var(--border-color);
  z-index: var(--z-header);
}

.header-button {
  @include size(1.5rem);
  cursor: pointer;
  color: var(--text-color);
  user-select: none;

  @include from(lg) {
    @include size(2rem);
  }
}
