.react-player {
  // ration 9:16
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  .player {
    position: absolute;
    top: 0;
    left: 0;
  }
}
