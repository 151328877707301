.dropdown-select {
  li {
    @include active-status {
      color: var(--blue);
    }
  }

  &__list {
    max-width: none !important;
    width: max-content !important;
  }
}
