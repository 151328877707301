body {
  margin: var(--header-height) 0 0 0;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  overflow-x: hidden;

  &.loggued {
    @include from(lg) {
      padding-left: var(--sidebar-width);
      transition: padding-left var(--transition-time);
    }
  }
}

main.main {
  padding-top: var(--main-padding);
  padding-bottom: var(--main-padding);
}

.ed-grid {
  @include to(m) {
    width: calc(100% - (var(--main-padding) * 2));
    margin-right: var(--main-padding);
    margin-left: var(--main-padding);
  }
}

.hidden {
  display: none;
}

ul.list-container {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.form-grid {
  --gap: 1rem;
  row-gap: 1.5rem !important;
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.m-48 {
  width: 48%;
}

/* Ratios */

.ratio-1-1 {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.ratio-3-2 {
  width: 150px;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.ratio-3-4 {
  width: 75px;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.ratio-4-3 {
  width: 133px;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.ratio-16-9 {
  width: 177px;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.ratio-18-6 {
  width: 300px;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.logo-container {
  position: relative;
}

.logo-container img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}

.transparent-hover {
  &:hover {
    background-color: transparent !important;
  }
}

@include prototype(to-bottom, margin-top, auto);
