.selected-company-section {
  width: 300px;

  @include from(lg) {
    width: 360px;
  }

  .company-grid {
    --size: 96px;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(var(--size), 1fr));
  }
}
