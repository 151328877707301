.breadcrumbs {
  & > * {
    padding: 0.2rem;
    margin: 0;
    display: inline;

    &:not(:last-child) {
      color: var(--light-text-color);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &::after {
        content: ' ›';
        display: inline;
      }
    }
  }

  & :last-child {
    color: var(--title-color);
  }
}
