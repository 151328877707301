$startSizeWave: 0.5;
$endSizeWave: 1.5;

.notification-button {
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .dot {
    z-index: 1;
  }

  .bell {
    transform-origin: 50% 0;
  }

  .wave {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid;
    border-color: transparent var(--red-accent);
    border-radius: 50%;
    transform: scale($startSizeWave);
    will-change: transform;
    opacity: 0;
  }

  &.animate {
    .bell {
      animation: ring 3s ease-out infinite;
    }

    .wave {
      animation: {
        duration: 3s;
        timing-function: ease-out;
        iteration-count: infinite;
        fill-mode: forwards;
      }

      &:nth-child(1) {
        animation-name: waves-1;
      }

      &:nth-child(2) {
        animation-name: waves-2;
      }

      &:nth-child(3) {
        animation-name: waves-3;
      }
    }
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  35% {
    transform: rotate(0);
  }

  40% {
    transform: rotate(-7deg);
  }

  45% {
    transform: rotate(7deg);
  }

  50% {
    transform: rotate(-7deg);
  }

  55% {
    transform: rotate(7deg);
  }

  60% {
    transform: rotate(-7deg);
  }

  65% {
    transform: rotate(7deg);
  }

  70% {
    transform: rotate(-7deg);
  }

  75% {
    transform: rotate(7deg);
  }

  80% {
    transform: rotate(-7deg);
  }

  85% {
    transform: rotate(7deg);
  }

  90% {
    transform: rotate(-7deg);
  }

  95% {
    transform: rotate(7deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes waves-1 {
  0%,
  39% {
    opacity: 0;
  }

  40% {
    opacity: 1;
    transform: scale($startSizeWave);
  }

  70% {
    opacity: 0;
    transform: scale($endSizeWave);
  }
}

@keyframes waves-2 {
  0%,
  54% {
    opacity: 0;
  }

  55% {
    opacity: 1;
    transform: scale($startSizeWave);
  }

  85% {
    opacity: 0;
    transform: scale($endSizeWave);
  }
}

@keyframes waves-3 {
  0%,
  69% {
    opacity: 0;
  }

  70% {
    opacity: 1;
    transform: scale($startSizeWave);
  }

  100% {
    opacity: 0;
    transform: scale($endSizeWave);
  }
}
