.form-step {
  --desactived-color: var(--light-text-color);
  --actived-color: var(--blue-accent);
  --item-margin: 0.25rem;
  --line-width: 2rem;

  .circle {
    border: 2px solid var(--actived-color);
    line-height: 1.8;
  }

  .text {
    @include from(lg) {
      white-space: nowrap;
    }
  }

  &:not(:last-of-type) {
    margin-right: calc((var(--item-margin) * 2) + var(--line-width));

    @include from(lg) {
      --item-margin: 1rem;
      --line-width: 3rem;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      transform: translateX(100%);
      right: calc(var(--item-margin) * -1);
      top: 6px;
      width: var(--line-width);
      border-top: 1.5px solid var(--actived-color);

      @include from(lg) {
        top: 12px;
      }
    }
  }

  &.is-error {
    --actived-color: var(--red-accent);

    .number {
      display: block;
    }

    .check {
      display: none;
    }

    .text {
      color: var(--actived-color);
    }

    &:not(.is-current) {
      .circle {
        background: var(--actived-color);
      }
    }
  }

  &.is-current {
    &::before {
      border-color: var(--desactived-color);
    }

    .circle {
      border-color: var(--actived-color);
      color: var(--actived-color);
      background: transparent;
    }

    .number {
      display: block;
    }

    .text {
      display: block;
    }

    .check {
      display: none;
    }

    ~ .form-step {
      color: var(--desactived-color);
      cursor: initial;

      .number {
        display: block;
      }

      .check {
        display: none;
      }

      .circle {
        color: inherit;
        border-color: var(--desactived-color);
        background: transparent;
      }

      .text {
        color: inherit;
      }

      &::before {
        border-color: var(--desactived-color);
      }
    }
  }
}
