.process-percentage-item {
  --process-color: var(--blue-accent);
  --percentage: 0;

  &.completado {
    --process-color: var(--green-accent);
  }

  &.error {
    --process-color: var(--yellow-accent);
  }

  .badge {
    background: var(--process-color);
  }

  .progress-bar {
    background: linear-gradient(
      90deg,
      var(--process-color) var(--percentage),
      var(--light-blue-accent) var(--percentage)
    );
  }
}
