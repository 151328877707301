.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.border-top {
  border-top: 1px solid var(--border-color);
}

.border-xy {
  border: 1px solid var(--border-color);
}

.border-bottom-white {
  border-bottom: 1px solid var(--white-color);
}

.border-bottom-white-2 {
  border-bottom: 2px solid var(--white-color);
}

.border-bottom-white-4 {
  border-bottom: 4px solid var(--white-color);
}
