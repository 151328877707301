.payroll-step {
  &:not(:last-of-type) {
    margin-right: 4rem;
  }

  &.is-current ~ & .dot {
    background: var(--blue-50);
  }
}

.table-current-step {
  .tooltip-template {
    padding: 0.5rem 2rem;
  }
}
