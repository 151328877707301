.payroll-summary-item {
  &.is-locked {
    opacity: 0.5;

    .content {
      color: var(--color-text);
    }
  }

  &.is-solved {
    .content {
      color: var(--green-accent);
    }
  }
}
