.main-sidebar {
  position: fixed;
  top: var(--header-height);
  height: calc(100% - var(--header-height));
  width: var(--sidebar-width);
  transform: initial;

  .#{$collapsed-sidebar} & {
    transform: translateX(-100%);
  }
}

body.#{$collapsed-sidebar} {
  padding-left: 0;
}
