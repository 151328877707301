.progress-bar {
  --background-color: var(--blue-50);
  --progress-color: var(--blue-500);

  appearance: none;
  background: var(--background-color);
  border: 0;

  &::-webkit-progress-bar {
    background: var(--background-color);
  }

  &::-webkit-progress-value {
    background: var(--progress-color);
  }
}
