.square-company-item {
  width: var(--size);

  &.add-button .square {
    background: var(--blue-100);

    &:hover {
      background: var(--blue-200);
    }
  }

  .square {
    @include size(var(--size));
    box-shadow: var(--card-shadow);
  }
}
