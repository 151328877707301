.notifications-panel {
  --max-height: calc(100vh - var(--header-height));

  width: 100%;
  max-height: var(--max-height);

  @include to(m) {
    position: fixed;
    top: var(--header-height);
    left: 0;
  }

  @include from(m) {
    position: absolute;
    right: -2rem;
    width: rem(450px);
    box-shadow: var(--modal-shadow);
  }

  .notifications-list {
    --size-header: calc(var(--h3-font-size) + var(--vertical-content-space));
    max-height: calc(var(--max-height) - (var(--container-padding) * 2) - var(--size-header));
    overflow-y: auto;
  }
}
