@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap');

:root {
  // Fonts
  --heading-font: 'Varela Round', sans-serif;
  --body-font: 'Varela Round', sans-serif;
  --code-font: 'Ubuntu Mono', monospace;

  // Line height
  --heading-line-height: 1.3;
  --body-line-height: 1.6;

  // Sizes
  @mixin base-font-size($name, $mobileSize, $desktopSize) {
    #{$name}: #{rem($mobileSize)};

    @include from(lg) {
      #{$name}: #{rem($desktopSize)};
    }
  }

  @include base-font-size(--h1-font-size, 26px, 32px);
  @include base-font-size(--h2-font-size, 20px, 26px);
  @include base-font-size(--h3-font-size, 16px, 20px);
  @include base-font-size(--h4-font-size, 14px, 16px);
  @include base-font-size(--normal-font-size, 14px, 16px);
  @include base-font-size(--small-font-size, 12px, 14px);
  @include base-font-size(--smaller-font-size, 10px, 12px);
  @include base-font-size(--smallest-font-size, 8px, 10px);
}

// Estilos base para las fuentes
// Si el segundo parámetro es true, entonces se aplican estilos de título, en caso contrario estilos de contenido
@mixin base-font-styles($size, $is-heading: false) {
  line-height: if($is-heading, var(--heading-line-height), var(--body-line-height));
  font-family: if($is-heading, var(--heading-font), var(--body-font));
  font-size: var(--#{$size}-font-size);

  @if ($is-heading) {
    color: var(--title-color);
    letter-spacing: 0.015em;
    font-weight: normal;
  }
}

@mixin h1 {
  @include base-font-styles(h1, true);
}

@mixin h2 {
  @include base-font-styles(h2, true);
}

@mixin h3 {
  @include base-font-styles(h3, true);
}

@mixin h4 {
  @include base-font-styles(h4, true);
}

@mixin normal {
  @include base-font-styles(normal);
}

@mixin small {
  @include base-font-styles(small);
}

@mixin smaller {
  @include base-font-styles(smaller);
}

@mixin smallest {
  @include base-font-styles(smallest);
}

.t1,
h1 {
  @include h1;
}

.t2,
h2 {
  @include h2;
}

.t3,
h3 {
  @include h3;
}

.t4,
h4 {
  @include h4;
}

.normal,
body {
  @include normal;
}

.small {
  @include small;
}

.smaller {
  @include smaller;
}

.smallest {
  @include smallest;
}

.heading-font {
  font-family: var(--heading-font);
}

.body-font {
  font-family: var(--body-font);
}

.code-font {
  font-family: var(--code-font);
}
