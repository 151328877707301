.modal-template {
  --modal-top: 4rem;
  --max-width-container: 450px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-modal);

  @include from(lg) {
    --modal-top: 6rem;
  }

  @media screen and (min-width: 1024px) and (max-height: 720px) {
    --modal-top: 3rem;
  }

  &.medium {
    --max-width-container: 550px;
  }

  &.large {
    --max-width-container: 700px;
  }

  &.jumbo {
    --max-width-container: 900px;
  }

  .wrapper {
    position: relative;
    top: var(--modal-top);
    width: calc(100% - 2rem);
    max-width: var(--max-width-container);
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .container {
    padding: var(--container-padding);
    width: 100%;
    border-radius: var(--big-radius);
    background-color: var(--container-bg);
    box-shadow: var(--modal-shadow);
    max-height: calc(100vh - (var(--modal-top) * 2));
    overflow: overlay;
    scrollbar-gutter: stable both-edges;

    &::-webkit-scrollbar {
      width: var(--big-radius);
    }

    &::-moz-scrollbar {
      width: var(--big-radius);
    }

    &::-webkit-scrollbar-track {
      border-radius: 0 var(--big-radius) var(--big-radius) 0;
      background: var(--container-bg);
    }

    &::-moz-scrollbar-track {
      border-radius: 0 var(--big-radius) var(--big-radius) 0;
      background: var(--container-bg);
    }

    &::-webkit-scrollbar-thumb {
      background: #d3d1cb;
      border-radius: 0 var(--big-radius) var(--big-radius) 0;
      border: var(--small-radius) solid var(--container-bg);
    }

    &::-moz-scrollbar-thumb {
      background: #d3d1cb;
      border-radius: 0 var(--big-radius) var(--big-radius) 0;
      border: var(--small-radius) solid var(--container-bg);
    }

    &::-webkit-scrollbar-thumb {
      &:hover,
      &:active {
        background: #b3b3b3;
        border: 5px solid var(--container-bg);
      }
    }

    &::-moz-scrollbar-thumb {
      &:hover,
      &:active {
        background: #b3b3b3;
        border: 5px solid var(--container-bg);
      }
    }
  }

  .close-button {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: none;
    outline: none;
    background-color: var(--red-accent);
    border-radius: 50%;
    cursor: pointer;
    transform: translate(35%, -35%);
    transition: background-color var(--transition-time);

    &:hover {
      background-color: var(--alt-red-accent);
    }

    .icon {
      fill: #fff;
      height: 2rem;
      width: 2rem;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.25);
    backdrop-filter: blur(5px);
  }

  // any element within the modal with float container set the position to fixed, so we
  // can select the element of the float container if its size is greather the modal
  .float-container {
    position: fixed;
  }
}
