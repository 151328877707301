table {
  width: 100%;
  text-align: left;
  margin: 0;

  th,
  td {
    padding: 0.75rem 1.5rem;
  }

  th {
    font-weight: normal;
    color: var(--title-color);
  }

  td {
    color: var(--light-text-color);
  }

  p {
    margin-bottom: 0;
  }
}
