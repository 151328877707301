$blue: (
  '50': #eff5ff,
  '100': #deecfe,
  '200': #bdd9fd,
  '300': #9dc5fc,
  '400': #7cb2fb,
  '500': #5b9ffa,
  '600': #4c84d0,
  '700': #3c69a6,
  '800': #2d4e7b,
  '900': #1d3451
);

// Colores de acentuación
$accent: (
  'blue-accent': var(--blue-500),
  'alt-blue-accent': var(--blue-600),
  'light-blue-accent': var(--blue-50),
  'yellow-accent': #ffefcb,
  'alt-yellow-accent': #ffd066,
  'red-accent': #df4e71,
  'alt-red-accent': #b83252,
  'light-red-accent': #fcecef,
  'green-accent': #37cb95,
  'alt-green-accent': #2ea97c,
  'light-green-accent': #ebfaf4
);

$text: (
  'title-color': #263540,
  'text-color': #596973,
  'light-text-color': #80929d,
  'lighter-text-color': #b5bbc0
);

$background: (
  'body-bg': #f6f9fb,
  'body-alt-bg': #fafafa,
  'container-bg': #fff,
  'input-bg': var(--body-alt-bg)
);

$others: (
  'border-color': #e4e7ec,
  'white-color': #fff,
  'black-color': #000,
  'current-color': currentColor,
  'transparent': transparent
);

// Prototipado de colores para usar los mapas de colors en fondos y textos
// Utilizando el prototipado de EDgrid
@mixin color-prototype($variable, $selector: $variable) {
  $value: var(--#{$variable});
  // Fondos y fondos con hover
  @include prototype(('bg-#{$selector}', 'hover-bg-#{$selector}:hover'), background-color, $value);
  // textos y textos con hover
  @include prototype(('color-#{$selector}', 'hover-color-#{$selector}:hover'), color, $value);
  // Fill para svg
  @include prototype('fill-#{$selector}', fill, $value);
  // Bordes
  @include prototype('border-#{$selector}', border, 1px solid $value);
  @each $direction in $directions {
    @include prototype(
      ('border-#{$direction}-#{$selector}', 'hover-border-#{$direction}-#{$selector}:hover'),
      border-#{$direction},
      1px solid $value
    );
  }
}

// Crear variables CSS utilizando los mapas de color
@mixin color-palette($color-map, $name: '', $replace: '') {
  $colors-length: length($color-map);
  $has-custom-name: $name != '';

  :root {
    @for $index from 1 through $colors-length {
      // Número que está en el nombre del color
      $key: nth(nth($color-map, $index), 1);
      // Código del color en HEX
      $value: nth(nth($color-map, $index), 2);
      // Selector construído
      // Si se le pasa un nombre como parámetro construirá por ejemplo 'blue-500'
      // En caso contrario sólo utiliza el nombre de la llave
      $selector: if($has-custom-name, $name + '-' + $key, $key);
      // Variable CSS
      --#{$selector}: #{$value};

      // Prototipado de colores (fondos y textos)
      @at-root {
        @if $has-custom-name {
          @include color-prototype($selector);
        } @else {
          @include color-prototype($selector, str-replace($selector, $replace, ''));
        }
      }
    }
  }
}

@include color-palette($blue, blue);
@include color-palette($accent, '', '-accent');
@include color-palette($text, '', '-color');
@include color-palette($background, '', '-bg');
@include color-palette($others, '', '-color');
