// Texto y decoraciones
.decoration-none {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-break-spaces {
  white-space: break-spaces;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.min-height-smaller {
  min-height: var(--smaller-font-size);
}
