.n-container-payslip-section {
  margin-bottom: 0;
  border-radius: var(--big-radius);
  background-color: var(--container-bg);
  box-shadow: var(--card-shadow);

  &.viewer {
    border-radius: var(--big-radius) var(--big-radius) 0 0;
  }
}
