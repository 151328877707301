.s-gap-12px {
  --gap: 12px;
}

// hack for logo size in the payslip
.s-h-80px {
  height: 80px;
}

.s-w-fit {
  width: fit-content;
}
