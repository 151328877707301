.nav-item {
  @include active-status {
    color: var(--blue-400);
    background: var(--light-blue-accent);
  }

  &.logout {
    @include active-status {
      color: var(--red-accent);
      background: var(--light-red-accent);
    }
  }
}
