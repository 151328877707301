.action-button {
  &.blue {
    background-color: var(--blue-100);

    .icon {
      fill: var(--blue-600);
    }

    .content {
      color: var(--blue-600);
    }

    &:hover {
      background-color: var(--blue-200);
    }
  }
}
