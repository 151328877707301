.come-back-header {
  // top       : var(--header-height);
  bottom: 0;
  height: var(--back-header-height);
  transform: translateX(100%);
  box-shadow: 2px 2px 2px 2px rgba(112, 112, 176, 0.2);

  .phone-panel.is-active & {
    transform: initial;
  }
}
