/* ----- Margin y paddings ----- */
@include breakpoints {
  @each $spacing in $spacings {
    // Padding top
    &-pt-#{$spacing} {
      padding-top: $spacing;
    }
    // Padding left
    &-pl-#{$spacing} {
      padding-left: $spacing;
    }
    // Padding bottom
    &-pb-#{$spacing} {
      padding-bottom: $spacing;
    }
    // Padding right
    &-pr-#{$spacing} {
      padding-right: $spacing;
    }
    // Padding vertical
    &-py-#{$spacing} {
      padding-top: $spacing;
      padding-bottom: $spacing;
    }
    // Padding horizontal
    &-px-#{$spacing} {
      padding-left: $spacing;
      padding-right: $spacing;
    }
    // Padding total
    &-pxy-#{$spacing} {
      padding: $spacing;
    }
    // Margin right
    &-mr-#{$spacing} {
      margin-right: $spacing;
    }
    // Margin bottom
    &-mb-#{$spacing} {
      margin-bottom: $spacing;
    }
    // Margin bottom (negativo)
    &-mt-#{$spacing} {
      margin-top: $spacing * -1;
    }
    // Margin top (negativo)
    &-ml-#{$spacing} {
      margin-left: $spacing * -1;
    }
  }
}
