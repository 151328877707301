// Cursor
.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.select-none {
  user-select: none;
}

.outline-none {
  outline: none;
}

.events-none {
  pointer-events: none;
}
