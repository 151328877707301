.search-input-button {
  max-width: 250px;

  &,
  .input {
    transition: width var(--long-transition-time);
  }

  .form-item,
  .input {
    width: 100%;
  }

  .input {
    border: none;
  }

  &:focus-within {
    width: 100%;

    .form-item {
      opacity: 1;
    }
  }

  &.not-empty {
    width: 100%;
  }
}
