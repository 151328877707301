.payslip {
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid var(--lighter-text-color);
    display: flex;
    font-size: var(--small-font-size);
  }

  th,
  td {
    flex: auto;
    flex-basis: 20%;
    padding: 0.35rem 0.35rem;

    &:first-of-type {
      padding-left: 0;
      flex-basis: 50%;
    }

    &:last-of-type {
      padding-right: 0;
      flex-shrink: 0;
    }

    &:not(:first-of-type) {
      text-align: right;
    }
  }

  th,
  td.total {
    color: var(--text-color);
  }

  td.total {
    text-align: right;
  }

  @media print {
    padding: 2rem;

    h2 {
      font-size: 1.625rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    h4 {
      font-size: 1rem;
    }

    .payslip-general-data {
      display: grid;
      grid-gap: var(--gap);
      gap: var(--gap);
      grid-template-columns: repeat(2, 1fr);
      width: var(--width);

      p {
        border-bottom: 1px solid var(--lighter-text-color);
      }
    }

    .tooltip-container {
      display: none;
    }
  }
}
