.help-panel {
  position: fixed;
  top: 4rem;
  right: 4rem;
  height: 80vh;
  width: 400px;
  max-width: 80vw;
  border-radius: 1rem;
  background-color: var(--white-color);
  z-index: 9999;
  overflow-y: hidden;
  box-shadow: var(--help-shadow);

  .help-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    padding: 1rem;
    border-radius: 1rem 1rem 0 0;
    background-color: var(--light-blue-accent);
    user-select: none;
    cursor: move;
  }

  .help-content {
    height: calc(80vh - var(--header-height));
    padding: 0 1rem 1rem 1rem;
    overflow-y: auto;
  }
}
