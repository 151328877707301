.table-markdown {
  background-color: #282a36;
  margin-bottom: 2rem;
  border-radius: var(--normal-radius);
  padding: 0.5rem;
  overflow-x: auto;
  display: block;
  white-space: nowrap;

  th:first-child,
  td:first-child {
    position: sticky;
    left: -0.5rem;
    padding-left: 0.5rem;
    z-index: 1;
    white-space: break-spaces;
    background-color: #282a36;
  }

  thead th {
    width: 100%;
    color: #f1fa8c;
    white-space: nowrap;
  }

  td,
  th {
    padding: 0.3rem;
  }

  td {
    color: #f8f8f2;
  }

  tr td em {
    color: #bd93f9;
    background-color: transparent;
    font-weight: normal;
    font-style: normal;
  }

  tr td strong {
    color: #ffb86c;
    background-color: transparent;
    font-weight: normal;
    font-style: normal;
  }

  tbody td {
    border-top: 1px solid #6272a4;
  }
}
