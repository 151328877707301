@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin variables-class($map, $property) {
  @each $key, $value in $map {
    :root {
      --#{$key}: #{$value};
    }

    .#{$key} {
      #{$property}: var(--#{$key});
    }
  }
}

@mixin prototype-list($list, $property, $name: '') {
  @include breakpoints {
    @each $item in $list {
      $selector: if($name != '', '-#{$name}-#{$item}', '-#{$item}');

      &#{$selector} {
        #{$property}: #{$item};
      }
    }
  }
}

@mixin active-status {
  &:hover,
  &.is-active,
  &.active {
    @content;
  }
}
