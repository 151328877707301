.loading {
  display: inline-block;
  width: rem(50px);
  height: rem(50px);
  border: 5px solid rgba(#fff, 0.3);
  border-top-color: var(--white-color);
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;

  &.blue {
    border: 5px solid rgba(#5b9ffa, 0.3);
    border-top-color: var(--blue-accent);
  }

  // Sizes
  &.small {
    width: rem(25px);
    height: rem(25px);
    border-width: 3px;
  }

  &.micro {
    width: rem(18px);
    height: rem(18px);
    border-width: 3px;
  }
}
