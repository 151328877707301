.accordion-trigger {
  .dropdown-icon {
    transition: transform var(--min-transition-time);
  }

  &.expanded {
    .dropdown-icon {
      transform: rotate(0.5turn);
    }
  }
}
