.phone-panel {
  --back-header-height: 2.5rem;

  @include to(m) {
    overflow: hidden;

    &.is-active {
      .panels {
        transform: translateX(calc(-100% - var(--main-padding)));
      }
    }

    .panels {
      grid-auto-columns: 100vw;
    }

    .off-canvas {
      margin-bottom: var(--back-header-height);
      padding-left: var(--main-padding);
      padding-right: var(--main-padding);
      grid-column-start: 2;
      grid-row-start: 1;

      &.mt-none {
        margin-top: 0;
      }
    }
  }
}
