.shadow-card {
  box-shadow: var(--card-shadow);
}

.shadow-modal {
  box-shadow: var(--modal-shadow);
}

.shadow-tooltip {
  box-shadow: var(--tooltip-shadow);
}
