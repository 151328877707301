// Flexbox
.flex-grow {
  flex-grow: 1;
}

.flex-none {
  flex: none;
}

.nowrap {
  flex-wrap: nowrap;
}

@include prototype-list($spacings, gap, 'flex-gap');

@include prototype(order-initial, order, initial);
