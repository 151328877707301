.calculation-modal {
  display: flex;
  justify-content: center;
  text-align: center;

  hr {
    margin: 0.75rem 0;
    border-color: var(--blue-200);
  }

  svg {
    fill: var(--blue-200);
  }

  .calculations {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 0.75rem;
  }
}
