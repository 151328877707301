.employees-panel {
  --employees-panel-header-height: 92px;
  --employees-panel-gap: 1.5rem;

  .panel-section:first-child {
    height: var(--employees-panel-header-height);
  }

  .panel-section:last-child {
    --employees-panel-list-height: calc(
      var(--viewport-height) - var(--employees-panel-header-height) -
        (var(--container-padding) * 2) - var(--employees-panel-gap)
    );
    height: var(--employees-panel-list-height);
    overflow-y: auto;
  }

  .panel-section:first-child,
  .panel-section:last-child {
    &.has-button {
      --employees-panel-header-height: 156px;
    }
  }
}

@include from(m) {
  --employees-panel-gap: 1rem;
}
