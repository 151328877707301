.tab-item {
  padding: 0.25rem 0.5rem;
  border-radius: var(--normal-radius);
  font-size: var(--small-font-size);
  color: var(--light-text-color);
  transition: background var(--transition-time);
  white-space: nowrap;

  @include active-status {
    cursor: pointer;
    color: var(--blue-800);
    background: var(--blue-100);
  }
}

.tab-container,
.tabs-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  overflow: auto;
}
