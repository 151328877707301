.container-full-screen {
  position: relative;

  &.expand {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-modal);
    background-color: var(--container-bg);
  }

  &.expand .content-full-screen {
    overflow-y: scroll;
    max-height: 95%;
  }

  &.container-formulator {
    min-height: 600px;
  }
}
