// Redondeado
.circle-radius {
  border-radius: 50%;
}

.object-contain {
  object-fit: contain;
}

.grayscale {
  filter: grayscale(100%);
}

/* ----- Border radius with css variables ----- */
@include variables-class($radius, border-radius);

@include prototype('to-bottom', margin-top, auto);
