*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color: var(--text-color);
  background: var(--body-bg);
}

a {
  color: var(--blue-accent);
}

::selection {
  background: var(--blue-accent);
  color: #fff;
}

hr {
  border: none;
  border-bottom: 1px solid var(--border-color);
  margin-left: initial;
  margin-right: initial;
}
