.carousel-grid {
  .grid {
    display: grid;
    gap: var(--gap);
    grid-template-columns: repeat(var(--columns), 1fr);
    width: var(--width);
  }

  .overflow {
    width: 100%;
  }
}
