.payments-table {
  .table-header {
    border-bottom: none;
  }

  .summary {
    @include to(m) {
      background: initial;
    }
  }

  .table {
    th {
      &:first-of-type {
        width: rem(60px);
      }

      &:last-of-type {
        position: static;
        pointer-events: initial;
        opacity: 1;
      }
    }

    .row-0 {
      @include to(m) {
        display: none;
      }
    }
  }
}
