.payroll-header {
  &.date-picker {
    .react-datepicker-popper {
      margin-top: -2rem;
    }

    .react-datepicker-wrapper {
      opacity: 0;
      position: absolute;
    }
  }
}
