[type='text'],
[type='email'],
[type='password'],
[type='number'],
[type='date'],
[type='time'],
[type='datetime-local'],
[type='search'],
[type='tel'],
[type='url'],
select,
textarea {
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: var(--normal-radius);
  background-color: var(--input-bg);
  line-height: var(--body-line-height);
  font-family: var(--body-font);
  font-size: var(--small-font-size);
  color: var(--text-color);

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--blue-accent);
  }

  &::placeholder {
    color: var(--lighter-text-color);
  }

  &:disabled {
    background-color: var(--border-color);
    cursor: not-allowed;
    max-height: rem(130px);
  }
}

textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: rem(116px);
}

[type='password'],
[placeholder='••••••••••']:placeholder-shown {
  letter-spacing: 0.25rem;
}

@function input-icon($icon) {
  @return var(--input-bg) url($icon) center right 1rem / 1rem no-repeat;
}

[type='search']:placeholder-shown {
  background: input-icon('../../../../static/icons/svg/search.svg');
}

input.date-picker {
  background: input-icon('../../../../static/icons/svg/calendar.svg');
}

select {
  appearance: none;
  background: input-icon('../../../../static/icons/svg/down.svg');
  padding-right: 2rem;
  text-overflow: ellipsis;

  &.has-placeholder:not(:focus) {
    color: var(--lighter-text-color);
  }
}

.radio-button {
  display: flex;
  align-items: center;
  font-size: var(--small-font-size);

  input {
    margin: 0;
    margin-right: rem(6px);
  }
}

.search-input {
  input {
    background: input-icon('../../../../static/icons/svg/search.svg');
    padding-right: 2rem;
  }
}

@mixin required-label {
  &::before {
    content: '*';
    margin-right: rem(6px);
    color: var(--blue-accent);
  }
}

.form-item {
  display: flex;
  flex-direction: column;

  input,
  select,
  textarea {
    &:required + label {
      @include required-label;
    }
  }

  // if we using third party library and we wrapper into form-item, we can pass this class
  // for  force the styles of required field
  &.is-required > label {
    @include required-label;
  }

  input[type='number'] {
    text-align: right;
  }

  label:not(.radio-button) {
    display: flex;
    text-align: left;
    font-size: var(--small-font-size);
    margin-bottom: rem(6px);
    order: -1;
  }

  .markdown,
  .markdown-preview {
    border-radius: var(--normal-radius);
    padding: 0.5rem 1rem;
    min-height: rem(116px);
    overflow: auto;

    &.s-height {
      max-height: rem(130px);
    }

    &.m-height {
      max-height: rem(300px);
    }

    &.l-height {
      max-height: rem(500px);
    }
  }

  .markdown {
    background-color: var(--border-color);
  }

  .markdown-preview {
    border: 1px solid var(--border-color);
    border-radius: var(--normal-radius);
  }

  &.small input {
    height: 2rem;
  }

  &.small select {
    height: 2rem;
    line-height: normal;
  }
}

label.required {
  @include required-label;
}

.checkbox-item {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    appearance: none;
    margin: 0;
    margin-right: rem(6px);
    outline: none;

    &::before {
      content: '';
      @include size(rem(14px));
      display: block;
      box-shadow: 0 0 0 2px var(--text-color) inset;
      border-radius: var(--small-radius);
    }

    &:checked::before {
      content: '✔';
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      background-color: var(--blue-accent);
      color: #fff;
      font-size: 0.65rem;
      line-height: 1rem;
    }
  }
}
