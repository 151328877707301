/* ----- Radius ----- */
$radius: (
  'big-radius': 1rem,
  'normal-radius': 0.5rem,
  'small-radius': 0.25rem
);

/* ----- Stacking context ----- */
$z-index: (
  'z-back': -10,
  'z-normal': 1,
  'z-tooltip': 10,
  'z-fixed': 20,
  'z-header': 30,
  'z-modal': 40
);

$collapsed-sidebar: 'collapsed-sidebar';

$spacings: 0, 2px, 4px, 6px, 8px, 10px, 12px, 16px, 20px, 24px, 28px, 32px, 48px, 64px, 128px;
$positions: 'static', 'relative', 'absolute', 'fixed', 'sticky';
$directions: 'top', 'right', 'bottom', 'left';
$overflow: 'hidden', 'scroll', 'auto', 'visible', 'overlay';

:root {
  /* ----- Sizes ----- */
  --header-height: 3rem;
  --sidebar-width: #{rem(240px)};
  --main-padding: 0.5rem;
  // (100vh) - (separción vertical del main) - (altura del header)
  --viewport-height: calc(100vh - (var(--main-padding) * 2) - var(--header-height));
  --top-sticky: calc(var(--header-height) + var(--main-padding));

  @include from(lg) {
    --header-height: 4rem;
  }

  /* ----- Shadows ----- */
  --modal-shadow: 0 1rem 40px #{rgba(#7070b0, 0.2)};
  --card-shadow: 0 2px 3px #{rgba(#495459, 0.1)};
  --help-shadow: 0 2px 3px #{rgba(#495459, 0.5)};
  --tooltip-shadow: 0 2px 8px #{rgba(#787d93, 0.15)};

  /* ----- Padding ----- */
  --container-padding: 1rem;

  @include from(m) {
    --main-padding: 1rem;
  }

  @include from(lg) {
    --container-padding: 2rem;
    --main-padding: 2rem;
  }
}
