.draggable {
  position: relative;

  &:hover {
    cursor: grab;
    transform: scale(0.95);
    transition: 0.2s;
  }

  .sequence {
    position: absolute;
    right: 0.4rem;
    bottom: 0.4rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.2rem;
    border-radius: 50%;
    border: 1px solid var(--text-color);
    font-size: var(--smaller-font-size);
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
