.tooltip-template {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -1rem;
  left: 50%;
  padding: rem(6px) 0.75rem;
  color: var(--border-color);
  background: var(--title-color);
  font-size: var(--small-font-size);
  border-radius: var(--normal-radius);
  transform: translate(-50%, -75%);
  opacity: 0;
  transition:
    transform var(--transition-time),
    opacity var(--transition-time);
  pointer-events: none;

  &::before {
    content: '';
    @include size(10px);
    position: absolute;
    background: inherit;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    border-radius: 2px;
  }
}

.tooltip-container {
  position: relative;

  &:hover .tooltip-template {
    pointer-events: initial;
    transform: translate(-50%, -100%);
    opacity: 1;
    z-index: var(--z-tooltip);
  }
}
