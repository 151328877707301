.react-dropdown-tree-select {
  display: block;
  width: 100%;
  font-family: var(--body-font);
  font-size: var(--small-font-size);
  color: var(--text-color);

  .dropdown-trigger {
    text-decoration: none;
  }

  .dropdown {
    position: relative;
  }

  .dropdown-content {
    position: absolute;
    min-width: rem(200px);
    max-height: 40vh;
    padding: 1rem;
    border-radius: var(--normal-radius);
    background-color: var(--container-bg);
    box-shadow: var(--tooltip-shadow);
    z-index: var(--z-tooltip);

    ul {
      margin: 0;
      padding: 0;
    }
  }

  .node {
    list-style: none;
    white-space: nowrap;
    padding: 0.2rem 1rem;
    display: flex;
    align-items: center;

    & label {
      display: flex;
      align-items: center;
      margin: 0;
      cursor: pointer;
    }

    &.leaf {
      &.collapsed {
        display: none;
      }
    }

    &.disabled > * {
      background: var(--border-color);
      color: var(--light-text-color);
      cursor: not-allowed;
    }

    &.match-in-children {
      &.hide {
        .node-label {
          opacity: 0.5;
        }
      }
    }

    &.focused {
      color: var(--blue-800);
      background: var(--blue-100);
    }
  }

  .toggle {
    order: -1;
    display: flex;
    line-height: normal;
    font-style: normal;
    padding: 0;
    margin-right: 0.5rem;
    color: var(--title-color);
    cursor: pointer;

    &.collapsed::before {
      width: 1rem;
      height: 1rem;
      content: url('../../../static/icons/svg/plus-square.svg');
    }

    &.expanded::before {
      width: 1rem;
      height: 1rem;
      content: url('../../../static/icons/svg/minus-square.svg');
    }
  }

  .searchModeOn .toggle {
    display: none;
  }

  .checkbox-item,
  .radio-item {
    margin: 0;
    margin-right: 0.25rem;

    &.simple-select {
      display: none;
    }
  }

  .tag-list {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .tag-item {
    width: 100%;

    .search {
      background: input-icon('../../../static/icons/svg/down.svg');
    }
  }

  .tag {
    position: absolute;
    display: block;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    width: 75%;
    padding: 0.2rem 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: var(--light-blue-accent);
    border-radius: var(--normal-radius);
    color: var(--blue-acent);
  }

  .tag-remove {
    position: absolute;
    right: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    outline: none;
    color: var(--alt-blue-accent);
    font-weight: bold;
    cursor: pointer;
    background-color: transparent;

    &.readOnly,
    &.disabled {
      cursor: not-allowed;
    }
  }
}
