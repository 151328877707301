.n-container {
  margin-bottom: 0;
  padding: var(--container-padding);
  border-radius: var(--big-radius);
  background-color: var(--container-bg);
  box-shadow: var(--card-shadow);
}

.n-container-none-padding {
  margin-bottom: 0;
  border-radius: var(--big-radius);
  background-color: var(--container-bg);
  box-shadow: var(--card-shadow);
}

.float-container {
  position: absolute;
  padding: 1rem;
  border-radius: var(--normal-radius);
  background-color: var(--container-bg);
  box-shadow: var(--tooltip-shadow);
  z-index: var(--z-tooltip);

  &.size-m {
    display: block;
    max-height: 40vh;
    overflow: auto;
  }
}
